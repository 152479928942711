export const BG_COVER = `https://ik.imagekit.io/adelinejune/HarisonElisa/FOTO%20COVER%20DEPAN.jpg?updatedAt=1700129766714`;
export const BG_WELCOMING_SECTION = `https://ik.imagekit.io/adelinejune/HarisonElisa/FOTO%20COVER%20DALAM.jpg?updatedAt=1700129766628`;
export const BG_COUNTING = `https://user-images.githubusercontent.com/10141928/146292235-21d28e45-72b7-41e1-90ac-26a3605529e2.jpg`;
export const BG_IG_FILTER = `https://ik.imagekit.io/adelinejune/HarisonElisa/COVER%20HARISON%20ELISA.jpg?updatedAt=1700799050579`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/adelinejune/HarisonElisa/Dekstop.jpg?updatedAt=1700129684142`;

export const IMG_AYAT = `https://ik.imagekit.io/adelinejune/HarisonElisa/Footer.jpg?updatedAt=1698211518376`;
export const IMG_LOGO = `https://ik.imagekit.io/adelinejune/HarisonElisa/Monogram.png?updatedAt=1699536128775`;

export const IMG_MAN = `https://ik.imagekit.io/adelinejune/HarisonElisa/Cover%20Gallery%202.jpg?updatedAt=1700129355890`;
export const IMG_GIRL = `https://ik.imagekit.io/adelinejune/HarisonElisa/Foto%20Bride.jpg?updatedAt=1698211517645`;
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/146289521-e97786dc-98a1-43d3-9b0f-e9353b168766.jpg`;

export const IMG_PHOTO_1 = `https://ik.imagekit.io/adelinejune/HarisonElisa/Cover%20Gallery%201.jpg?updatedAt=1698211557300`;
export const IMG_PHOTO_2 = `https://ik.imagekit.io/adelinejune/HarisonElisa/Foto%20Groom.jpg?updatedAt=1698211517483`;
export const IMG_PHOTO_CONTENT = `https://user-images.githubusercontent.com/10141928/162121692-3e7a781f-6b2c-4cc4-be9e-004df53eb69d.jpg`;

export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/103095433/241068547-20f04f29-1531-4bc2-a5e5-c7e3ce1c2805.png`,
  en: `https://user-images.githubusercontent.com/103095433/241068561-c7d17d12-c32f-44a1-834a-af73e655b0b6.jpg`,
};

export const LOGO_INVITATO = `https://ik.imagekit.io/invitatoid/global-assets/invitato_hs3nyqsuwy_.png?updatedAt=1638541184695`;

//Collaboration
export const LOGO_VENDOR_DALANG = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-dalang_bp5JgCazz.png?ik-sdk-version=javascript-1.4.3&updatedAt=1651639908257`;
export const LOGO_VENDOR_PENTONE_WHITE = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-pentone-white_4cMUJafwM.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654518552744`;
export const LOGO_VENDOR_PENTONE_BLACK =
  'https://user-images.githubusercontent.com/103095433/193169607-562d8316-835d-4c15-a6b4-2b3c7cd22621.png';
export const LOGO_VENDOR_TIRTOWENING =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-tirtowening_2o6jmuIlN.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911787';
export const LOGO_VENDOR_SAMAWA =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-samawa_KniSfe28i.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911529';
export const LOGO_VENDOR_SADEWA =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-sadewa_6J3D8PmsJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911185';
export const LOGO_VENDOR_PRAHASTA =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-prahasta-white_5L4WQTBlc.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910828';
export const LOGO_VENDOR_FLATTER =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-flatter_SvHO8RoVF.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910005';
export const LOGO_VENDOR_AKSARA =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-aksara-white_Ofc6Xkib-.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708909659';
export const LOGO_VENDOR_DREAMWORKS =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-dreamworks-white_FFlUUi8Dk.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910073';
